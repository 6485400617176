import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

function FourOhFour() {
  const [query, setQuery] = React.useState("");
  const router = useRouter();
  const search = React.useCallback(() => {
    router.push(`/jobs/search?query=${query}`);
  }, [query, router]);

  return (
    <div className="m-12">
      <Head>
        <meta name="robots" content="noindex nofollow" key="robots" />
      </Head>

      <h1 className="text-3xl font-bold mb-4">404 - Seite nicht gefunden</h1>
      <p>
        Die angeforderte Seite konnte nicht gefunden werden. Bitte überprüfen
        Sie die URL und versuchen Sie es noch einmal oder nutzen Sie unsere
        Suche, um Stellenanzeigen zu finden.
      </p>
    </div>
  );
}

export { getStaticProps } from "@lib/utils/translationsStaticProps";
export default FourOhFour;
